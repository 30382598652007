/* Pretendard */
@font-face {
  font-family: 'Pretendard';
  src: url('/public/fonts/Pretendard-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/public/fonts/Pretendard-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/public/fonts/Pretendard-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/public/fonts/Pretendard-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/public/fonts/Pretendard-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/public/fonts/Pretendard-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/public/fonts/Pretendard-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/public/fonts/Pretendard-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/public/fonts/Pretendard-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

/* NotoSans */
@font-face {
  font-family: 'NotoSansSC';
  src: url('/public/fonts/NotoSansSC-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSansSC';
  src: url('/public/fonts/NotoSansSC-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSansSC';
  src: url('/public/fonts/NotoSansSC-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSansSC';
  src: url('/public/fonts/NotoSansSC-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSansSC';
  src: url('/public/fonts/NotoSansSC-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSansSC';
  src: url('/public/fonts/NotoSansSC-Black.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSansSC';
  src: url('/public/fonts/NotoSansSC-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

/* AppleSDGothic */
@font-face {
  font-family: 'AppleSDGothicNeo';
  src: url('/public/fonts/AppleSDGothicNeoT.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  src: url('/public/fonts/AppleSDGothicNeoUL.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  src: url('/public/fonts/AppleSDGothicNeoL.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  src: url('/public/fonts/AppleSDGothicNeoR.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  src: url('/public/fonts/AppleSDGothicNeoM.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  src: url('/public/fonts/AppleSDGothicNeoSB.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  src: url('/public/fonts/AppleSDGothicNeoB.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  src: url('/public/fonts/AppleSDGothicNeoEB.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'AppleSDGothicNeo';
  src: url('/public/fonts/AppleSDGothicNeoH.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
